html,
body {
  height: 100%;
  margin: 0;
}

.bottomTab {
  background: white;
  border: solid 0px var(--chakra-colors-brand-main);
}

.activeBottomTab {
  color: var(--chakra-colors-brand-main);
  border-top: solid 4px var(--chakra-colors-brand-main);
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: none;
  scrollbar-color: #959697 transparent;
  caret-color: transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #959697;
  border-radius: 10px;
  border: 3px solid transparent;
}

.item-border {
  box-shadow: var(--chakra-shadows-xl);
  border-radius: var(--chakra-radii-xl);
  overflow: hidden;
}

input,
textarea {
  caret-color: auto;
}

input:focus-visible:not(.react-datetime-picker__inputGroup__input) {
  border-color: #3794aa !important;
  box-shadow: 0 0 0 1px #3794aa !important;
}

input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
}

/* Global Classes */
.text-grey-centered-heading {
  text-align: center;
  font: normal normal bold 16px/21px Montserrat;
  letter-spacing: 0px;
  color: #595959;
  opacity: 1;
}

.text-grey-centered {
  text-align: center;
  font: normal normal normal 15px/21px Roboto;
  letter-spacing: 0px;
  color: #595959;
  opacity: 1;
}

.footer-grey-centered {
  text-align: center;
  font: normal normal medium 14px/21px Roboto;
  letter-spacing: 0px;
  color: #595959;
  opacity: 1;
}

.hidden {
  display: none;
}

.show-more-text {
  color: var(--chakra-colors-highlight);
  cursor: pointer
}



.datepicker {
  width: 100%;
  min-width: 0px;
  cursor: pointer;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
  padding-left: 10px;
}

.react-datetime-picker__wrapper {
  border: none
}

.react-datetime-picker__calendar.react-datetime-picker__calendar--open {
  z-index: 3;
}

.react-datetime-picker__inputGroup__input:invalid {
  background: transparent;
}

.react-datetime-picker__inputGroup__input:focus {
  outline: none !important;
}

.react-datetime-picker__calendar {
  width: 300px;
}

.react-calendar__tile--active {
  background-color: var(--chakra-colors-brand-main) !important;
}

.react-calendar__tile--now {
  background: transparent;
  border: 1px solid var(--chakra-colors-brand-main) !important;
}

.react-calendar__month-view__days__day--weekend {
  color: var(--chakra-colors-appGray) !important;
}